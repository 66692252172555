import logo from './logo.svg';
import './App.css';

import React, {useMemo, useEffect, useState, createContext} from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import { PublicClientApplication } from '@azure/msal-browser';
import MicrosoftLogin from "react-microsoft-login";

import Admin from './routes/Admin.js';
import Approver from './routes/Approver.js';
import Basic from './routes/Basic.js';
import Waitlist from './routes/Waitlist.js';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

export const ProviderContext = createContext()
export const UserContext = createContext()

const server =  process.env.NODE_ENV == 'development'? 'http://localhost:7071' : 'https://capex-server.azurewebsites.net'
const config = {
  auth:{
    clientId: 'd9dfd59f-241a-426d-aecb-4c82499dca37',
    authority:'https://login.microsoftonline.com/9b36f09b-be5b-4016-97b5-6a955ee0ee33',
    redirectUri: '/',
    scopes: ['user.read']
  }
}
const msalInstance = new PublicClientApplication(config)
await msalInstance.initialize();

function App() {
  
  const [loggedIn, isLoggedIn] = useState(false)
  const [loading, isLoading] = useState(false)
  const [profile, isProfile] = useState(null)
  const [permission, isPermission] = useState(null)
  const [permissionExtra, isPermissionExtra] = useState(null)
  const [departments, isDepartments] = useState(null)
  const [error, isError] = useState({
    error: false,
    message: ''
  })
  const env = process.env
 

  const getUser = async (acc) => {
    console.log('search for user ===> ',acc.username)

    var requestOptions = {
      method: 'POST',
      redirect: 'follow'
    };
    
    await fetch(`${env.NODE_ENV == 'development'? 'http://localhost:7071' : 'https://capex-server.azurewebsites.net'}/api/capex/getUser?id=${acc.username}`, requestOptions)
    .then(response => response.text())
    .then(result => {
      let res = JSON.parse(result)
      // console.log('the user is ==> ', JSON.parse(result))

      if(res?.length == 0){
        createUser(acc)
      }
      else {
        // console.log('permission is =====> ', res[0].Permission)
        isPermission(res[0].Permission)
        isPermissionExtra(res[0].ExtraPermission)
        isDepartments(res[0].Department)
        isLoggedIn(true)
      }
 
      

    })
    .catch(error => {
      console.log('error', error)
      isLoading(true)
      isError({
        error:true,
        message: error.message
      })

      console.log(error)
    });
  }
  const createUser = async (acc) => {
    console.log('create the following user ===> ', acc)
    var requestOptions = {
      method: 'POST',
      redirect: 'follow',
      body: JSON.stringify({
        ID: acc.username,
        Name: acc.name,
        Joined: new Date,
        Permission: 'null',
        Banned: false,
        Department: null
      })
    };
    
    await fetch(`${server}/api/capex/createUser`, requestOptions)
    .then(response => response.text())
    .then(result => {
      // console.log('new user has been registered ', result)
      isPermission(null)
      isLoggedIn(true)
    })
    .catch(error => {
      console.log('error', error)
      isLoading(false)

      isError({
        error:true,
        message: error
      })
    });
  }

  const checkForExistingSession = async () => {
    console.log('check on existing session')
    isLoading(true)
    try {
      const accounts = await msalInstance.getAllAccounts()
      console.log('hi ==> ', accounts)
      
      if(accounts.length > 0){
        const account = accounts[0]
        isProfile({
          name: account.name,
          username: account.username
        })
        getUser(account)
        isLoading(true)
      }
      else isLoading(false)
    }
    catch (e) {
      console.log('error ===> ',e)
      isLoading(false)
      isError({
        error:true,
        message: e
      })
    }
  }

  const login = async () => {
    isLoading(true)
    try {
      const loginResponse = await msalInstance.loginPopup({});
      console.log('login response ==> ', loginResponse)
      
      if(loginResponse){
        const account = loginResponse.account
        isProfile({
          name: account.name,
          username: account.username
        })
        getUser(account)
        isLoading(true)
      }
       
    } catch (err) {
      isLoading(false)
      isError({
        error:true,
        message: err
      })
    }
  }


  useEffect(() => {
    checkForExistingSession()
  },[])

  return (
    <>
      {!loggedIn?
        (
          <div className='row w-100 h-100'>
            <div className='col-xl-2 col-1'></div>

            <div className='col-xl-8 col-10'>
              <div className='custom-card login'>

                <div className='row' style={{height:'20%'}}>
                  <div className='col-12 text-center'>
                    <img width={75} style={{borderRadius:100}} src={require('../src/gfx/capex-logos.jpeg')} />
                  </div>
                </div>

                
                {error.error &&
                  <div className='errorBox'>
                    <p className='mb-0'>An error occured: <b>{error.message}.</b></p>
                    <hr/>
                    <p className='mb-0'>Refresh the page to fix it or submit a ticket via HelpDesk if the issue persists</p>
                  </div>
                }

                
                <div className='row' style={{height:'55%'}}>
                  <div className='col-12'>
                    <div className='d-flex' style={{width:250,height:'100%',justifyContent:'center',alignItems:'center',margin:'auto'}}>
                      {!loading?

                        (
                          

                          <div className='loginBtn d-flex' onClick={() => login()}>
                            <img 
                              src='https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/2048px-Microsoft_logo.svg.png'
                              style={{
                                  width:25,
                                  height:25,
                                  marginRight:15,
                                  marginLeft:15
                              }}
                            />
                            
                            <h6 className='mb-0'>Sign in with Microsoft</h6>
                          </div>
                        )
                        :
                        (
                          <Player
                            autoplay
                            loop
                            src='https://lottie.host/8e354e2b-9458-4249-8351-ae798b347213/zjmKClqW1t.json'
                            style={{ width: '100%',justifyContent:'center', alignItems:'center'}}
                          />
                  
                        )
                    
                      }
                    
                    </div>
                  </div>
                </div>   

                <div className='row text-center' style={{height:'20%'}}>
                  <div className='col-12'>
                    <img width={200} src={require('../src/gfx/capex-logos_transparent.png')} />
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        

         
          
        )
        :
        (
          <div className='row w-100' style={{marginTop:'1.5%',height:'93%'}}>
            
            
            <div className='col-12 h-100'>
              <ProviderContext.Provider value={{access:permission, profile: profile, env: server}}>
                <Routes>
                  <Route 
                    path="/" 
                    element={
                      <>
                        {(permission == 'null' || permission == null || !permission) &&
                          <Waitlist/>
                        }   

                        {permission == 'basic' &&
                          <Basic/>
                        }  
                        {permission == 'admin' &&
                          <Admin Permission={permissionExtra} Departments={departments}/>
                        }

                        {(permission !== 'admin' && permission !== 'basic' && permission !== 'null') &&
                          <Approver Departments={departments}/>
                        }

                        

                        
                      </>
                     
                      
                    } 
                  />
                </Routes>
              </ProviderContext.Provider>
            </div>
          </div>
        )
      }
    </>
  );
}

export default App;
