import React, { useState, useEffect, useContext, useMemo } from "react"
import { Chart, Doughnut, Pie  } from 'react-chartjs-2';
import 'chart.js/auto'; // ADD THIS

import { ProviderContext } from "../App";
import NavbarAdmin from "../components/NavbarAdmin";
import BottomNavbar from "../components/BottomNavbar";
import FilterCard from "../components/Cards/FilterCard";
import RequestCard from "../components/Cards/RequestCard"
import Ring from "../components/ring";

import StatCard from "../components/Cards/StatCard";
import StatCardRound from "../components/Cards/StatCardRound";

import { Player, Controls } from '@lottiefiles/react-lottie-player';

import Home from "../subroutes/Admin/Home";
import Approver from "../subroutes/Admin/Approver";
import Requests from "../subroutes/Admin/Requests";
import Capex from "../subroutes/Admin/Capex";
import Settings from "../subroutes/Admin/Settings";



/* eslint-disable padded-blocks */
 

let Admin = (props) => {
    const {profile,env} = useContext(ProviderContext)
    const ExtraPermission = props.Permission
    const departments = props.Departments
    const [data, isData] = useState([])
    const [selected, isSelected] = useState('Dashboard')
    const [users, isUsers] = useState([])
    const [loaded, isLoaded] = useState(false)
    const [statsRequest, isStatsRequest] = useState({
        approved:0,
        pending:0,
        rejected:0,
        total:0, 
        spent:0,
    })

    const [spentMonthly, isSpentMonthly] = useState({
        sep:0,oct:0,nov:0,dec:0,jan:0,feb:0,mar:0,apr:0,may:0,jun:0,jul:0,aug:0
    })

    const [budgetPerDepartment, isBudgetPerDepartment] = useState({
        label: [],
        data: []
    })
    const [animate, isAnimate] = useState(true)
    const [fiscalBudget, setFiscalBudget] = useState(null)
    const [allfiscal, setAllFiscal] = useState(null)

    // console.log('this permission ==> ',Departments)
    
    let getFiscalBudget = async () => {
        const requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
    
        try {
            const response = await fetch(`${env}/api/capex/getFiscalBudget`, requestOptions);
            const result = await response.json();
            console.log('fiscal budget ==> ',result[0], new Date() < new Date(result[0].to))
            setFiscalBudget(result[0])
            setAllFiscal(result)
            getRequests()
        } catch (error) {
            console.log('error', error);
        }
    }
    let getRequests = async () => {
        // console.log('check for new requests...');
    
        const requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
    
        try {
            console.time('get all requests fetch times')
            const response = await fetch(`${env}/api/capex/getRequestsAll`, requestOptions);
            const result = await response.json();
           
            isData(result)
            isLoaded(true)

            console.timeEnd('get all requests fetch times')
    
            setTimeout(getRequests, 5000);
        } catch (error) {
            console.log('error', error);
        }
    };
    

    let getRegisteredUsers = async () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
          
        await fetch(`${env}/api/capex/getRegisteredUser`, requestOptions)
        .then(response => response.text())
        .then(result => {
            // console.log('all users ==> ', JSON.parse(result))
            let res = JSON.parse(result)
           
            isUsers(res)
            
            setTimeout(getRegisteredUsers, 5000)

        })
        .catch(error => {
            console.log('error', error)
        });

    }
   

    useMemo(() => {
        getRegisteredUsers()
        getFiscalBudget()

        const url = new URL(window.location.href);
        const paramValue = url.searchParams.get('id');

        if(paramValue && paramValue.length > 1){
            console.log('param values ===> ',paramValue)
            isSelected('Approve')
        }
    },[])


  
    return (
        <div className="row h-100" >

       
          
            <div className='col-xl-2 col-lg-2 col-md-2 d-flex h-100 mobilecontrol' style={{justifyContent:'center',alignItems:'center'}}>
                <NavbarAdmin 
                    onClick1={() => isSelected('Dashboard')}
                    onClick5={() => isSelected('Approve')}
                    onClick2={() => isSelected('Requests')}
                    onClick3={() => isSelected('Capex')}
                    onClick4={() => isSelected('Settings')}
                    Selected = {selected}
                    ExtraPermission={ExtraPermission?.includes('approver')}
                />
            </div>

            {loaded ?
                (
                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 h-100">
                        {selected == 'Dashboard' &&
                            <Home FiscalBudget={fiscalBudget} Loaded={loaded} Users={users} Env={env} AllFiscal={allfiscal}  />
                        }

                        {(selected == 'Approve' && ExtraPermission?.includes('approver')) &&
                            <Approver Permission={ExtraPermission} Departments={departments} env={env} data={data} profile={profile} />
                        }
        
                        {selected == 'Requests' &&
                            <Requests env={env} data={data} profile={profile} />
                        }

                        {selected == 'Capex' &&
                            <Capex env={env} data={data} FiscalBudget={fiscalBudget?.fiscal} />
                        }
        
                        {selected == 'Settings' &&
                            <Settings Users={users} FiscalBudget={fiscalBudget} Spent={statsRequest?.spent} />
                        }
        
                        <BottomNavbar 
                            onClick1={() => isSelected('Dashboard')}
                            onClick4={() => isSelected('Approve')}
                            onClick2={() => isSelected('Requests')}
                            onClick3={() => isSelected('Settings')}
                            ExtraPermission = {ExtraPermission?.includes('approver')}
                            Selected = {selected}
                        />
                    
                    </div>
                )

                :

                (
                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 h-100">
                        
                        <div className={`custom-card animate__animated animate__fadeIn`}>
                            <div class="d-flex justify-content-center w-100 h-100">
                                <div class="spinner-border text-primary loader" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                )
            }
           
           
        </div>
    )
}

export default Admin